<template>
  <div class="full-width full-height pattern">
    <v-app-bar
      app
      outlined
      flat
      tile
      color="white"
      style="z-index: 1001; overflow: hidden"
    >
      <div class="d-flex align-center" style="gap: 20px; width: 30%">
        <v-img
          v-if="logo"
          :src="logo"
          :height="50"
          :width="50"
          :max-height="50"
          :max-width="50"
          cover
          style="border-radius: 15px"
          :alt="account.companyName"
          :title="account.companyName"
        />
        <div v-if="title" class="font-weight-semibold text-h5">
          <v-menu offset-y v-if="isVendorHomeRoute" :min-width="350">
            <template v-slot:activator="{ on, attrs }">
              <cz-button :title="title" text v-on="on" v-bind="attrs">
                <span class="text-h5 font-weight-semibold">{{ title }}</span>
                <cz-icon :src="mdiChevronDown" :size="30" class="mr-2" />
              </cz-button>
            </template>
            <v-list subheader two-line nav>
              <v-list-item-group
                v-if="otherVendors && otherVendors.length"
                color="primary darken-3"
              >
                <v-subheader
                  class="text-body-1 font-weight-semibold d-flex align-center"
                  style="column-gap: 10px"
                >
                  <cz-icon :size="20" :src="mdiSwapHorizontal" />
                  <span>{{ $t('vendor.home.otherVendors') }}</span></v-subheader
                >
                <v-list-item
                  v-for="item in otherVendors"
                  :key="item._id"
                  @click="onSwitchVendor(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-semibold">
                      {{ `${item.businessName} (${item._id})` }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.statusText
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2" />
              </v-list-item-group>
              <v-list-item-group>
                <v-list-item
                  :to="{
                    name: 'vendor-onboarding',
                    params: {
                      id: 'new'
                    }
                  }"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-body-1 font-weight-semibold textPrimary--text d-flex align-center"
                      style="column-gap: 10px"
                    >
                      <cz-icon
                        :src="mdiShapeRectanglePlus"
                        :size="30"
                        color="primary"
                      />
                      <span>{{ $t('vendor.home.newVendor') }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <span v-else>{{ title }}</span>
        </div>
      </div>
      <div style="width: 40%" class="d-flex justify-center">
        <v-img
          :src="corzLogo"
          :height="60"
          :width="60"
          :max-height="60"
          :max-width="60"
          :title="$t('common.appTooltip')"
        />
      </div>
      <div style="width: 30%" class="d-flex justify-end">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <cz-avatar
              v-if="currentUser"
              :size="50"
              color="primary"
              :name="currentUser.fullName"
              initials-color="textPrimary"
              :src="currentUserProfilePicture"
              initials-size="font-weight-semibold text-h6"
              v-on="on"
              v-bind="attrs"
            />
          </template>
          <v-list disabled>
            <v-list-item>
              <v-list-item-title>
                {{ currentUser.fullName }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                {{ currentUser.email }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list>
            <v-list-item
              :disabled="$route.name === 'edit-profile'"
              class="d-flex align-center"
              @click="onEditProfile"
            >
              <v-list-item-icon class="mx-2"
                ><cz-icon :size="22" :src="mdiAccountEdit"
              /></v-list-item-icon>
              <v-list-item-title>{{
                $t('auth.editProfile')
              }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              :disabled="$route.name === 'edit-profile'"
              class="d-flex align-center textPrimary--text"
              href="mailto://hello@corz.app"
              target="_blank"
            >
              <v-list-item-icon class="mx-2"
                ><cz-icon :size="22" :src="mdiFaceAgent"
              /></v-list-item-icon>
              <v-list-item-title>{{
                $t('common.contactSupport')
              }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              class="d-flex align-center textPrimary--text"
              href="https://storage.googleapis.com/corz-files-public/terms.pdf"
              target="_blank"
            >
              <v-list-item-icon class="mx-2"
                ><cz-icon :size="22" :src="mdiShieldAccountOutline"
              /></v-list-item-icon>
              <v-list-item-title>
                {{ $t('common.terms') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              class="d-flex align-center"
              @click="logoutDialog.show = true"
            >
              <v-list-item-icon class="mx-2"
                ><cz-icon :size="22" :src="mdiLogoutVariant"
              /></v-list-item-icon>
              <v-list-item-title>{{ $t('auth.logout') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <router-view />

    <logout-dialog
      v-if="logoutDialog.show"
      v-model="logoutDialog.show"
      v-bind="logoutDialog"
      @close="logoutDialog.show = false"
      @logout="onLogout"
    />
  </div>
</template>

<script>
import { CzAvatar, CzIcon, CzButton } from '@/components';
import LogoutDialog from '@/domain/auth/components/LogoutDialog.vue';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import corzLogo from '@/assets/corz.png';
import {
  userHasRoleWithName,
  userHasOneOfRolesWithName
} from '@/shared/services/rbac/rbac.service';
import {
  mdiLogoutVariant,
  mdiAccountEdit,
  mdiChevronDown,
  mdiSwapHorizontal,
  mdiShapeRectanglePlus,
  mdiFaceAgent,
  mdiShieldAccountOutline
} from '@mdi/js';
import RoleType from '@/shared/types/RoleType';
export default {
  name: 'Home',
  components: {
    CzAvatar,
    CzIcon,
    CzButton,
    LogoutDialog
  },
  computed: {
    ...mapFields('auth', ['currentUser', 'account', 'abilities']),
    ...mapFields('vendor', ['vendor', 'vendors']),
    title() {
      if (this.isSuperAdminRoute) {
        return this.$t('admin.homeTitle');
      } else if (this.isCustomerRoute) {
        return this.account.companyName;
      } else if (this.isNewVendorRoute) {
        return this.$t('vendor.home.newVendorTitle');
      } else if (this.vendor) {
        return `${this.vendor?.businessName} (${this.vendor?._id})`;
      }
    },
    otherVendors() {
      return this.vendors?.filter((item) => item._id !== this.vendor._id);
    },
    isSuperAdminRoute() {
      return this.$route.matched.some(
        ({ name }) => name === 'super-admin-home'
      );
    },
    isVendorHomeRoute() {
      return this.$route.matched.some(({ name }) => name === 'vendor-home');
    },
    isCustomerRoute() {
      return this.$route.matched.some(({ name }) => name === 'customer');
    },
    isNewVendorRoute() {
      return (
        this.$route.name === 'vendor-onboarding' &&
        this.$route.params?.id === 'new'
      );
    },
    isVendorOnBoardingRoute() {
      return this.$route.name === 'vendor-onboarding';
    },
    logo() {
      if (this.account?.logo) {
        return this.account.logo.url;
      } else {
        return null;
      }
    },
    currentUserProfilePicture() {
      if (this.currentUser.profilePicture?.url) {
        return this.currentUser.profilePicture.url;
      } else {
        return null;
      }
    }
  },
  created() {
    this.$recaptchaInstance.hideBadge();
    if (!this.account) {
      //TODO: if user has no account, navigate to 404 page
      return;
    }

    if (
      userHasRoleWithName(this.currentUser, RoleType.VENDOR_ADMIN) &&
      !this.isVendorHomeRoute
    ) {
      this.$router.replace({
        name: 'vendor-home'
      });
    } else if (
      userHasRoleWithName(this.currentUser, RoleType.SUPER_ADMIN) &&
      !this.isSuperAdminRoute
    ) {
      // route user to super admin dashboard
      this.$router.replace({
        name: 'super-admin-home'
      });
    } else if (
      userHasOneOfRolesWithName(this.currentUser, [
        RoleType.CUSTOMER_ADMIN,
        RoleType.CUSTOMER_AUTHORIZER,
        RoleType.CUSTOMER_OBSERVER,
        RoleType.CUSTOMER_PURCHASER,
        RoleType.CUSTOMER_CATALOG_ADMIN
      ]) &&
      !this.isCustomerRoute
    ) {
      // route user to super admin dashboard
      this.$router.replace({
        name: 'customer'
      });
    }
  },
  data() {
    return {
      corzLogo,
      mdiLogoutVariant,
      mdiShieldAccountOutline,
      mdiChevronDown,
      mdiFaceAgent,
      mdiAccountEdit,
      mdiShapeRectanglePlus,
      mdiSwapHorizontal,
      logoutDialog: {
        show: false
      }
    };
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('vendor', ['switchVendor']),
    ...mapActions('ui', ['showSuccessToastMessage']),
    async onLogout() {
      try {
        await this.logout();
        this.$router.replace({
          name: 'login'
        });
      } finally {
      }
    },
    onEditProfile() {
      this.$router.push({
        name: 'edit-profile'
      });
    },
    onNewVendor() {
      this.$router.push({
        name: 'vendor-onboarding',
        params: {
          id: 'new'
        }
      });
    },
    async onSwitchVendor(vendor) {
      this.$router.replace({
        name: 'vendor-home',
        params: {
          id: vendor._id
        }
      });
      this.showSuccessToastMessage(
        this.$t('vendor.home.vendorSwitchSuccess', [vendor.businessName])
      );
    }
  },
  watch: {}
};
</script>

<style scoped lang="scss">
.pattern {
  // background-image: url('../assets/pattern.png');
  // background-size: cover;
  // background-repeat: no-repeat;
}
</style>
